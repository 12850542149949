.imageSlider {
  width: 100%;
  display: inline-block;
}

.ProductDetail {
  display: inherit;
  padding-top: 100px;
}

.col2-productDetail {
  padding-top: 100px;
}

div.productDescription {
  font-family: "Montserrat" !important;
}

.productDescription h1 {
  font-weight: 400 !important;
  font-size: 3.5vh;
  letter-spacing: 0.25px;
  color: #060607;
  opacity: 1;
}
.productDescription h3 {
  padding-top: 20px;
  font-weight: 300 !important;
  font-style: italic;
  font-size: 2.3vh;
  letter-spacing: 0.25px;
  color: #5f5f5f;
  border-color: #a39d9d;
  opacity: 1;
}

.codigoValor {
  font-style: italic;
  font-size: 2.3vh;
  color: #707070;
  opacity: 1;
  padding-left: 10px;
}

.codigoLabel {
  display: flex;
}

.tallaLabel {
  display: flex;
}

.tallaIndividual {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a39d9d;
  opacity: 1;
  padding: 3px 15px 3px 15px;
  margin-left: 8px;
  text-align: center;
  display: inline;
}
