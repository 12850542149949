div.products {
  font-family: "Montserrat" !important;
  text-align: center;
  margin-bottom: 50px;
}

div.products img {
  width: 193px;
  height: 369px;
}

div.imagen-detalle {
  position: relative;

  margin: 5px;
}
div.imagen-detalle:hover img {
  opacity: 0.3;
}
div.imagen-detalle:hover .boton-ver {
  display: block;
}
div.imagen-detalle .boton-ver {
  position: absolute;
  display: none;
}
div.imagen-detalle .boton-ver {
  top: 40%;
  left: 40%;
}
.products h2 {
  font-weight: 400 !important;
  font-size: 2.5vh;
  letter-spacing: 0.25px;
  color: #837a7a;
  opacity: 1;
}

div.precio-final {
  font-weight: 600 !important;
  padding-right: 5px;
  letter-spacing: 0.25px;
  color: #080808;
  opacity: 1;
}

div.descuento {
  font-weight: 100 !important;
  color: #ffffff;
  font-style: italic;
  background-color: red;
  padding-left: 5px;
  padding-right: 5px;
  border-color: #faf7f7;
  border-width: 1px;
}

div.precio {
  text-decoration: line-through;
  font-weight: 300 !important;
  padding-left: 5px;
  font-style: italic;
  letter-spacing: 0.25px;
  color: #a0a0a0;
  opacity: 1;
}

div.resumen-precio {
  display: inline-flex;
  text-align: center;
}

.react-rater {
  display: block !important;
}

.react-rater-star.is-active {
  color: #f5cb0f !important;
}

.container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.container img {
  width: 100%;
  height: auto;
}

.container .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.btn-outline-dark:hover {
  background-color: #1b4f728c!important;
  border-color:#1b4f723b !important;
}
