.col-fabric-detail.col {
  text-align: center;
  vertical-align: middle;
  background-color: #f3faff;
  font-size: 1.35rem;
  font-weight: 80;
  margin: 1.5em;
  border-style: solid;
  border-color: rgba(10, 181, 238, 0.13);
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50%;
  position: static;
  width: 50em;
  height: 12em;
}

.col-fabric-detail.col div {
  margin-top: 3em 1em 1em 1em;
}

div.divCharacteristics {
  display: flex;
  justify-content: center;  
  background-color: #f3faff;
  padding: 5em 0em 5em 0em;  
  
}

@media all and (min-width:150px) and (max-width: 700px) {
  div.row{
    display: inline;
  }
}