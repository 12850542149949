form.suscribeForm {
  font-family: "Montserrat" !important;
  text-align: center;
  padding-top: 15vh;
  padding-left: 10%;
  padding-right: 10%;
}

form.suscribeForm h1 {
  font-size: 7vh !important;
  line-height: 1.5 !important;
  font-weight: 200 !important;
  font-style: normal !important;
}

div.form-row {
  display: flex;
  padding-top: 6vh;
  padding-bottom: 15vh;
  justify-content: center;
}

.btn-primary {
  border-radius: 0% !important;
  background-color: #2C6CE4 !important;
  border-color: #2C6CE4 !important;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  width: 25vh !important;
}
.btn-primary:hover {
  background-color: #6AE6FB !important;
  border-color:#6AE6FB !important;
}

.form-control {
  border-radius: 0% !important;
  padding-top: 37px !important;
  padding-bottom: 37px !important;
  width: 60vh !important;
}

@media all and (min-width: 150px) and (max-width: 926px) {
  .btn-primary {
    width: 60vh !important;
  }
}

.rowToast.toast {
  padding: 0px;
}
.form-row {
  padding: 0px !important;
}

div.fade.toast.show {
  width: 85vh;
  max-width: 1000px!important;
  background-color: #a5ffa5;
}

