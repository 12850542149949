div.footer.col {
  background: linear-gradient(130deg,#00f2ff  0%, #0700dd 100%) ;
  text-align: center;
  justify-content: center;
}

.footer svg {
  padding: 5px;
}

.socialIcons.row {
  margin-top: 40px !important;
  display: flex !important;
  justify-content: center;
}

.socialIcons {
    padding-top: 10px;
}

.firstHr {
  border: 1px solid #f3faff;
  width: 100%;
}

.secondHr {
  border: 1px solid #f3faff;
  width: 90%;
}

div.trademark.row {
  text-align: center;
  display: block !important;
  padding-bottom: 10px;
  font-family: "Montserrat" !important;
  font-weight: 200 !important;
  letter-spacing: 0.4px;
  color: #ffffff;
  opacity: 1;
}

div.callUs.row h2{
    font-family: "Montserrat" !important;
    font-weight: 200 !important; 
    font-size: 1.5em !important; 
    font-style: italic;
    text-align: left;
    opacity: 1;
    color: #ffffff;
    padding-left: 80px;
}

.callUs {
  padding-top: 50px;
}

.footer {
 margin-top: 50px;
}