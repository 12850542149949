.StickyWhatsApp img {
  -webkit-transition: 0.5s; /* Safari prior 6.1 */
  transition: 0.5s;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 100px;
  width: 100px;
}

.StickyWhatsApp img:hover {
  -webkit-transition: 0.5s; /* Safari prior 6.1 */
  transition: 0.5s;
  cursor: pointer;
  height: 120px;
  width: 120px;
}
