
/* palette "Dust Palette" https://mycolor.space/?hex=%231B4F72&sub=1 */
.space-between {
    margin-right: 80px;
    margin-left: 80px;
}

.bgg-dark {
     
    background: linear-gradient(130deg,#00f2ff  0%, #0700dd 100%) ;  
}

a{
    color: #fff!important;
}

a.active {    
    color: #F1FAFF !important;
    border-bottom:1px solid white;
}

a:hover {   
    text-decoration: none !important;
}

.nav-item {
    padding-top:10px;
}